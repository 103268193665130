import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  initialColorMode: 'light',
  useSystemColorMode: false,
  styles: {
    global: {
      'html, body': {
        bg: '#FFEAEA',
      },
    },
  },
  fonts: {
    heading: `'Cheri', sans-serif`,
  },
  components: {
    Button: {
      baseStyle: {
        fontFamily: 'Cheri',
      },
      sizes: {
        lg: {
           fontSize: "52px",
           px: 10,
           py: 10,
        }
     },
      variants: {
        default: {
          bg: "#fff",
          color: '#F14040',
          borderRadius: '14px',
          _hover: {
             bg: "#FFEAEA"
          },
       },
      },
      defaultProps: {
        size: "lg",
        variant: "default"
     },
    },
    Heading: {
      baseStyle: {
        color: '#F14040'
      },
      sizes: {
        xl: {
          fontSize: '52',
        },
      },
    },
    Text: {
      baseStyle: {
        color: '#F14040'
      }
    },
  }
})

export default theme