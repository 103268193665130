import {useState, useEffect} from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import Homepage from './Homepage';
import theme from './theme'
import Particles, {initParticlesEngine} from "@tsparticles/react";
import {loadSnowPreset} from "@tsparticles/preset-snow"
import particlesOptions from "./particles.json";
import './index.css';

function App() {
  const [init, setInit] = useState(false);

  useEffect(() => {
      if (init) {
        return;
      }
      initParticlesEngine(async (engine) => {
        await loadSnowPreset(engine);
      }).then(() => {
        setInit(true);
      });
  }, []);

  return (
    <ChakraProvider theme={theme}>
      {init && <Particles options={particlesOptions}/>}
      <div className="App">
        <Homepage />
      </div>
    </ChakraProvider>
  )
}

export default App;
