import React, {useState} from 'react';
import Countdown from 'react-countdown';
import { Input, Button, Center, Stack, Text, Heading, useToast, ScaleFade, useDisclosure, Image } from '@chakra-ui/react'
import Gift from './Gift';
import Question from './Question';

const gifts = [
  {
    title: "You got it right!",
    desc: "Ok now it's time to claim your gifts!",
    imageSrc: "https://media.tenor.com/u9gayqFngpMAAAAM/gift-pepe.gif"
  },
  {
    title: "Shein Gift Card 50€",
    desc: "omg dress omg earrings omg necklace omg 50 euro whatever you want omg",
    imageSrc: "https://cdn.shopify.com/s/files/1/0573/3207/2630/products/shein_cardimage-new_525x.progressive.jpg?v=1637794866"
  },
  {
    title: "Unlimited Kisses",
    desc: "omg you get unlimited kisses for whole valentine's day, enjoyyy",
    imageSrc: "https://i.pinimg.com/originals/dc/dd/45/dcdd45bc02f89241babe4ab61ce69f01.gif"
  },
  {
    title: "League skin of your choice!",
    desc: "damn, i might redeem this for myself ngl",
    imageSrc: "https://blenderartists.org/uploads/default/d89b58688e2b1b0d8fc256a4cdd6a454e49ac2a2",
    smallDesc: "*claiming this gift implies that you have to boost your husband in league until he gets silver. this text has been set to very small size cause of an ongoing error on the website, and with no intention at all of scamming any asian users."
  },
  {
    title: "No more lmao",
    desc: "u think im rich. maybe u get some more things outside of website if u behave",
    imageSrc: "https://media.tenor.com/d2-UHAzefXgAAAAi/pepe-cringe.gif"
  }
]

function Gifts({clickedOpenGifts, setClickedOpenGifts}) {
  const toast = useToast()
  const [showGifts, setShowGifts] = useState(false)
  const [showQuestions, setShowQuestions] = useState(false)
  const [giftCount, setGiftCount] = useState(0)
  const { isOpen, onToggle } = useDisclosure()

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [mathAnswers, setMathAnswers] = useState(false)

  const [finished, setFinished] = useState(false)

  useState(() => {
    setShowQuestions(localStorage.getItem('showQuestions') ?? false);
    setShowGifts(localStorage.getItem('showGifts') ?? false);
    setFinished(localStorage.getItem('finished') ?? false);
    onToggle()
  }, [])

  const finish = () => {
    setShowGifts(false)
    localStorage.setItem("showGifts", false)
    setShowQuestions(false)
    localStorage.setItem("showQuestions", false)
    setFinished(true)
    localStorage.setItem("finished", true)

  }

  const openRealGifts = () => {
    setShowGifts(true)
    localStorage.setItem("showGifts", true)
  }

  const openGifts = () => {
    setClickedOpenGifts(true)
    localStorage.setItem("clickedOpenGifts", true)
    toast.closeAll()
    toast({
      title: "LMAO you actually thought I wouldn't block the button? XDD",
      status: 'error',
      duration: 15000,
      isClosable: true,
    })
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Center><Button onClick={() => {
        setShowQuestions(true)
        localStorage.setItem("showQuestions", true)
      }}>Claim gifts</Button></Center>;
    } else {
      // Render a countdown
      return (
        <React.Fragment>
          <Center>
            <Heading color="#606060" userSelect='none'>{days}d {hours}h {minutes}m {seconds}s</Heading>
          </Center>
          <Center>
            <Button isDisabled={true} onClick={openGifts}>Claim gifts</Button>
          </Center>
        </React.Fragment>
      );
    }
  };

  return (
    <ScaleFade initialScale={0.8} in={isOpen}>
      <Stack>
          {showGifts ?
            <React.Fragment>
              <Gift title={gifts[giftCount].title} imageSrc={gifts[giftCount].imageSrc} desc={gifts[giftCount].desc} smallDesc={gifts[giftCount].smallDesc} />
              {giftCount == gifts.length-1 ?
                <Button onClick={() => {
                  finish()
                }}>Finish</Button>
              : null}
              {giftCount != gifts.length-1 ?
              <Button onClick={() => {
                if (giftCount != gifts.length-1) {
                  setGiftCount(giftCount+1)
                }
              }}>{giftCount == 0 ? 'Claim gifts' : 'Next'}</Button>
              :null}
              {giftCount != 0 ?
              <Button onClick={() => {
                if (giftCount != 0) {
                  setGiftCount(giftCount-1)
                }
              }} size="sm">Back</Button>
            : null}
            </React.Fragment>
          : showQuestions ?
          <React.Fragment>
            <Question title="Please solve this equation so I know you are not a robot" desc="(3 + i) x + i = 5i ; Solve for x.">
              {mathAnswers ?
                <React.Fragment>
                  <Button mt='20px' size="sm" backgroundColor="#FFEAEA" onClick={() => {
                    toast.closeAll()
                    toast({
                      title: "???",
                      status: 'error',
                      duration: 15000,
                      isClosable: true,
                    })
                  }}>x is 5</Button>
                  <Button size="sm" backgroundColor="#FFEAEA" onClick={() => {
                    toast.closeAll()
                    toast({
                      title: "???",
                      status: 'error',
                      duration: 15000,
                      isClosable: true,
                    })
                  }}>x is 12</Button>
                  <Button size="sm" backgroundColor="#FFEAEA" onClick={() => {
                    toast.closeAll()
                    toast({
                      title: "Okkkkk lets gooo",
                      status: 'success',
                      duration: 4000,
                      isClosable: true,
                    })
                    openRealGifts()
                  }}>I love my husband</Button>
                </React.Fragment>
              : <React.Fragment><Input mt='30px' mb="30px" placeholder='Answer' onChange={() => {
                setButtonDisabled(false)
              }}/>
              <Button isDisabled={buttonDisabled} onClick={() => {
                toast({
                  title: "Ok stop crying, I'll give you some options",
                  status: 'error',
                  duration: 4000,
                  isClosable: true,
                })
                setMathAnswers(true)
              }}>Submit</Button>
              </React.Fragment>
              }
            </Question>
          </React.Fragment>
          : finished ?
          <React.Fragment>
            <Center>
              <Image width="200px" marginBottom={6} src='https://cdn.7tv.app/emote/62af89d111218d43c4aae647/4x.webp' />
            </Center>
            <Center>
              <Heading>Happy Valentines day!</Heading>
            </Center>
            <Center>
              <Heading>Yay</Heading>
            </Center>
            <Button mt='20px' size="sm" onClick={() => {
                setMathAnswers(true)
                setShowGifts(true)
                localStorage.setItem("showGifts", true)
                setFinished(false)
                localStorage.setItem("finished", false)
              }}>Back</Button>
          </React.Fragment>
          :
          <React.Fragment>
            <Center>
              <Heading fontSize='180' marginLeft="-15px" marginBottom="-30px" fontFamily="Cheri Liney, sans-serif">YAY</Heading>
            </Center>
            <Center>
              <Heading>You said yes!</Heading>
            </Center>
            <Center>
              <Text textAlign='center' mb={20} w="350px" fontSize="20px" fontWeight='bold'>
                I'm so glad you didn't say no. Now I guess you have some gifts to claim :) But please promise me you won't claim them until Valentine's Day! Love you :)
              </Text>
            </Center>
            {!clickedOpenGifts ? 
            <Center>
              <Button onClick={openGifts}>Claim gifts</Button>
            </Center>
            : <Countdown date={new Date(1707865200000)} renderer={renderer} />}
          </React.Fragment>
          }
        
      </Stack>
    </ScaleFade>
  );
}

export default Gifts;