import React from 'react';
import { Stack, Image, Heading, Center, Text } from '@chakra-ui/react'

function Gift({title, imageSrc, desc, smallDesc}) {
  return (
    <Stack maxW='350px' borderRadius={8} padding={10} paddingTop='50px' backgroundColor="#fff" justifyContent='center'>
      <Center>
        <Image borderRadius={8} marginBottom={6} src={imageSrc} />
      </Center>
      <Heading textAlign='center' size="lg">{title}</Heading>
      <Text textAlign='center' mb={20} fontSize="20px" fontWeight='bold' size="lg" marginBottom={0}>{desc}</Text>
      <Text textAlign='center' mb={20} mt='10px' fontSize="8px" fontWeight='bold' size="xs" marginBottom={0}>{smallDesc}</Text>
    </Stack>
  );
}

export default Gift;