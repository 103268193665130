import React, {useState} from 'react';
import { Button, Center, Stack, useToast, Text, Heading, Spinner, ScaleFade, useDisclosure } from '@chakra-ui/react'
import Gifts from './Gifts';

const messages = ['Issok to make mistakes birdie, try again <3', 'Looooool try again m8', 'Im so handsome', 'and sexy', 'Let me take off the shoelace in front of you, so you can see the yes button!', 'You still haven’t realized that button doesn’t work?? LMAO get trolled ROFL XDD LOOOOOL', 'IT DOESN’T WORK LOL XDDDDDDDD', 'Say yes', 'You need to press the yes button', 'Please? :(', ':(']

function Homepage() {
  const toast = useToast()
  const [clickedOpenGifts, setClickedOpenGifts] = useState()
  const [clickedYes, setClickedYes] = useState()
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const { isOpen, onToggle } = useDisclosure()

  useState(() => {
    setClickedYes(localStorage.getItem('clickedYes') ?? false);
    setClickedOpenGifts(localStorage.getItem('clickedOpenGifts') ?? false);
    setTimeout(() => {
      setLoading(false)
      onToggle()
    }, 1500)
  },[])

  const yes = () => {
    toast.closeAll()
    setClickedYes(true)
    onToggle()
    localStorage.setItem("clickedYes", true)
  }

  const no = () => {
    toast.closeAll()
    toast({
      title: messages[count],
      status: 'error',
      duration: null,
      isClosable: true,
    })
    if (count != messages.length-1) {
      setCount(count+1)
    }
  }

  return (
    <Center h="100vh" position="relative">
      {!loading ?
      <Stack userSelect='none'>
        {!clickedYes ? 
        <ScaleFade initialScale={0.8} in={isOpen}>
          <Stack alignItems='center' mb={10}>
            <Heading fontSize='200' marginBottom="-30px" fontFamily="Cheri Liney, sans-serif">Birdie</Heading>
            <Heading size="xl">will you be my valentine?</Heading>
          </Stack>
          <Center>
            <Button onClick={yes} mb={3}>Yes</Button>
          </Center>
          <Center>
            <Text onClick={no} cursor='pointer' color="#505050" fontFamily="Cheri, sans-serif" userSelect='none'>no lmao</Text>
          </Center>
        </ScaleFade>
        :
        <Gifts clickedOpenGifts={clickedOpenGifts} setClickedOpenGifts={setClickedOpenGifts}/>
        }
      </Stack>
      : <Spinner color="#F14040" size="lg" speed='0.65s' thickness='4px'/>}
    </Center>
  );
}

export default Homepage;